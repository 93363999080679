export default function VoedingProductInfo(details) {
  return (
    <>
      <p>- Vermogen: {details?.Vermogen?.Gezamenlijk}</p>
      {/* Mooier formaat maken met split range van laagste tot hoogste geluidsniveau */}
      <p>- Geluidsniveau: mooier formaat maken</p>
      <p>- Kenmerk: {details?.Kenmerken}</p>
      <p>- Standaard: {details?.Standaard}</p>
    </>
  );
}
