import { FaTrash, FaEdit, FaCheck } from "react-icons/fa";
import { useState } from "react";
import useProducts from "../api/producten";
import CurrentUserCanManageReview from "./authentication/CurrentUserCanManageReview";

export default function Review({
  productID,
  reviewID,
  userID,
  name,
  date,
  message,
  removeHandler,
}) {
  const [isEditable, setIsEditable] = useState(false);
  const [currentMessage, setCurrentMessage] = useState(message);
  const { updateReview } = useProducts();
  const formattedDate = new Date(date).toLocaleDateString();
  return (
    <div className="max-w-full bg-base-300 rounded-lg p-3 mt-3">
      <div className="flex place-content-between">
        <div>
          <p className="font-medium">{name}</p>
          <p>{formattedDate}</p>
        </div>
        <CurrentUserCanManageReview userID={userID}>
          <div>
            {isEditable ? (
              <button
                className="btn btn-square"
                onClick={() => {
                  setIsEditable(false);
                  updateReview(productID, reviewID, currentMessage);
                }}
                data-cy="editReviewCheck"
              >
                <FaCheck size={"1.7em"} />
              </button>
            ) : (
              <button
                className="btn btn-square"
                onClick={() => setIsEditable(true)}
                data-cy="editButtonReview"
              >
                <FaEdit size={"1.7em"} />
              </button>
            )}

            <button
              className="btn btn-square ml-3"
              onClick={removeHandler}
              data-cy="deleteReviewButton"
            >
              <FaTrash size={"1.7em"} />
            </button>
          </div>
        </CurrentUserCanManageReview>
      </div>
      {isEditable ? (
        <textarea
          className="textarea textarea-primary bg-base-300 w-full mt-3"
          value={currentMessage}
          onChange={(e) => setCurrentMessage(e.target.value)}
          data-cy="textareaEditReview"
        />
      ) : (
        <p className="mt-3 overflow-hidden">{currentMessage}</p>
      )}
    </div>
  );
}
