export default function CaseFanProductInfo(details) {
  return (
    <>
      <p>- soort: {details.Soort}</p>
      <p>- toerental: {details.Toerental}</p>
      <p>- geluidssterkte: {details.Geluidsniveau}</p>
      <p>- bevestiging: {details.Bevestiging}</p>
    </>
  );
}
