import { Routes, Route } from "react-router-dom";
import AuthLanding from "./components/authentication/AuthLanding";
import RequireAuth from "./components/authentication/RequireAuth";
import { Home, ProductPage, Favorieten, NotFound } from "./pages";

function App() {
  //Set default theme in local storage to dark if there is no theme key in local storage
  if (!localStorage.getItem("theme")) {
    localStorage.setItem("theme", "dark");
  }

  return (
    <Routes>
      <Route index element={<Home />} />
      <Route
        path="/product/:productCategory/:productID"
        element={<ProductPage />}
      />
      <Route
        path="/favorieten"
        element={
          <RequireAuth>
            <Favorieten />
          </RequireAuth>
        }
      />
      <Route path="/login" element={<AuthLanding />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
