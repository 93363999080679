export default function GrafischeKaartenProductInfo(details) {
  return (
    <>
      <p>- Grafische chip: {details["Grafische chip"].Naam}</p>
      <p>
        - Geheugen:{" "}
        {details.Geheugen.Beschikbaar.slice(
          0,
          details.Geheugen.Beschikbaar.lastIndexOf(")")
        ) +
          details.Geheugen["Geheugen interface"] +
          details.Geheugen.Beschikbaar.slice(
            details.Geheugen.Beschikbaar.lastIndexOf(")")
          )}
      </p>
      <p>
        - Bezette slots:{" "}
        {details["Bezette sloten"]
          ? details["Bezette sloten"]
          : "Geen informatie"}
      </p>
      {details["Grafische chip"].Klokfrequentie ? (
        <p>- GPU-snelheid: {details["Grafische chip"].Klokfrequentie}</p>
      ) : (
        <p>
          - Geheugensnelheid: {details.Geheugen["Geheugensnelheid (effectief)"]}
        </p>
      )}
    </>
  );
}
