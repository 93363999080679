import { FaExclamation } from "react-icons/fa";

export default function Error({ error }) {
  if (error) {
    return (
      <div className="alert alert-error shadow-lg max-w-max">
        <FaExclamation size="1.7em" />
        <h4>Er is een error opgetreden.</h4>
        {error.message || JSON.stringify(error)}
      </div>
    );
  }

  return null;
}
