import { FaHome, FaHeart, FaSearch } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import AuthenticationButton from "../authentication/AuthenticationButton";
import ToggleThemeButton from "../ToggleThemeButton";
import { memo } from "react";

export default memo(function Navbar({
  query,
  setQuery,
  setSearch,
  setHomeButtonPressed,
}) {
  const location = useLocation();

  function handleChange(e) {
    setQuery(e.target.value);
  }

  function handleSearch() {
    setSearch(query);
    setQuery("");
  }

  return (
    <nav>
      <div className="navbar bg-base-100 px-24 justify-center gap-10 xl:gap-0">
        <div className="xl:flex-1 xl:justify-start">
          <Link
            to="/"
            className={`btn ${
              location.pathname === "/" ? "btn-primary" : ""
            } btn-square`}
            onClick={() => setHomeButtonPressed(true)}
            data-cy="homePageButton"
          >
            <FaHome size="1.7em" />
          </Link>
        </div>
        <input
          type="search"
          placeholder="Zoek producten"
          className="input input-bordered input-primary w-full max-w-xs hidden xl:block"
          value={query}
          onChange={handleChange}
          onKeyPress={(e) => e.key === "Enter" && handleSearch()}
        />
        <button className="btn ml-2 hidden xl:block" onClick={handleSearch}>
          <FaSearch size="1em" />
        </button>
        <div className="xl:flex-1 xl:justify-end gap-10 xl:gap-2">
          <AuthenticationButton />
          <Link
            to="/favorieten"
            className={`btn ${
              location.pathname === "/favorieten" ? "btn-primary" : ""
            }  btn-square`}
            data-cy="favorietenPageButton"
          >
            <FaHeart size="1.7em" />
          </Link>

          <ToggleThemeButton />
        </div>
      </div>
    </nav>
  );
});
