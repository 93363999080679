import axios from 'axios';
import {
    useAuth0
} from '@auth0/auth0-react';
import {
    useCallback
} from 'react';


const baseUrl = 'https://what-parts-should-i-buy-api.onrender.com/api/products';

const useProducts = () => {
    const {
        getAccessTokenSilently,
    } = useAuth0();

    const getAll = useCallback(async ({
        controller,
        limit,
        offset,
        category,
        search
    }) => {
        let token;
        try {
            token = await getAccessTokenSilently();
        } catch (error) {
            token = undefined;
        }

        const {
            data
        } = await axios.get(baseUrl, {
            signal: controller.signal,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            params: {
                limit,
                offset,
                category,
                search: search.trim()
            }
        });
        return data;
    }, [getAccessTokenSilently]);

    const getById = useCallback(async (productID) => {
        let token;
        try {
            token = await getAccessTokenSilently();
        } catch (error) {
            console.log('user not loggged in');
        }

        const {
            data
        } = await axios.get(`${baseUrl}/${productID}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return data.item;
    }, [getAccessTokenSilently]);

    const getAllReviewsForProduct = useCallback(async (productID) => {
        const {
            data
        } = await axios.get(`${baseUrl}/${productID}/reviews`);
        return data.items;
    }, []);

    const createReview = async (productID, message) => {
        const token = await getAccessTokenSilently();

        let data = JSON.stringify({
            "bericht": message
        });

        let config = {
            method: 'post',
            url: `${baseUrl}/${productID}/reviews`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        const response = await axios(config);
        return response.data;
    };

    const deleteReview = async (productID, reviewID) => {
        const token = await getAccessTokenSilently();

        await axios.delete(`${baseUrl}/${productID}/reviewsGebruiker`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: {
                'reviewID': reviewID
            }
        })
    }

    const updateReview = async (productID, reviewID, message) => {
        const token = await getAccessTokenSilently();

        let data = JSON.stringify({
            "reviewID": reviewID,
            "message": message
        });

        let config = {
            method: 'put',
            url: `${baseUrl}/${productID}/reviewsGebruiker`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        await axios(config);
    }

    return {
        getAll,
        getById,
        getAllReviewsForProduct,
        createReview,
        deleteReview,
        updateReview
    };
}

export default useProducts;