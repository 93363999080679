export default function SsdProductInfo(details) {
  return (
    <>
      <p>- Capaciteit: {details?.Capaciteit}</p>
      <p>
        - Snelheid: Lezen: {details?.Overdrachtssnelheid?.Lezen}, Schrijven:{" "}
        {details?.Overdrachtssnelheid?.Schrijven}
      </p>
      {details?.IOPS ? (
        <p>
          - IOPS: Lezen: {details?.IOPS?.Lezen}, Schrijven:{" "}
          {details?.IOPS?.Schrijven}
        </p>
      ) : (
        <p>- Bouwvorm: {details?.Bouwvorm}</p>
      )}
    </>
  );
}
