export default function PcBehuizingProductInfo(details) {
  return (
    <>
      <p>
        - Inbouwsloten: {details?.["Drive bays"]?.['3,5"']?.intern}{" "}
        {details?.["Drive bays"]?.['2,5"']?.intern}
      </p>
      <p>- Vormfactor: {details?.["Moederbord vormfactor"]}</p>
      <p>- Type voeding: {details?.["Interne afmetingen"]?.["Voeding type"]}</p>
      <p>
        - Full size slots:{" "}
        {details?.Slotopeningen?.["Full-size slots"]?.charAt(0)}
      </p>
    </>
  );
}
