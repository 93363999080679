import ProductCard from "./ProductCard";
import useFavorites from "../api/favorieten";
import { useEffect, useState, useMemo } from "react";
import Pagination from "./Pagination";
import {
  CpuProductInfo,
  CaseFanProductInfo,
  CpuKoelersProductInfo,
  GeheugenProductInfo,
  GrafischeKaartenProductInfo,
  HardeSchijvenProductInfo,
  MoederbordProductInfo,
  PcBehuizingProductInfo,
  SsdProductInfo,
  VoedingProductInfo,
  WaterkoelingProductInfo,
} from "./productCardInfo/index";
import toast, { Toaster } from "react-hot-toast";
import { Menu } from "@headlessui/react";
import Error from "./Error";
let PageSize = 24;

const categories = {
  alles: "",
  "cpu-koelers": "cpu koelers",
  cpus: "cpu's",
  geheugen: "geheugen",
  "grafische-kaarten": "grafische kaarten",
  "harde-schijven": "harde schijven",
  moederborden: "moederborden",
  ssds: "ssd's",
  voedingen: "voedingen",
  waterkoeling: "waterkoeling",
  "pc-behuizingen": "pc behuizing",
  "case-fans": "case fans",
};

const typeOfProducts = {
  "case-fans": (options) => <CaseFanProductInfo {...options.details} />,
  "cpu-koelers": (options) => <CpuKoelersProductInfo {...options.details} />,
  cpus: (options) => <CpuProductInfo {...options.details} />,
  geheugen: (options) => <GeheugenProductInfo {...options.details} />,
  "grafische-kaarten": (options) => (
    <GrafischeKaartenProductInfo {...options.details} />
  ),
  "harde-schijven": (options) => (
    <HardeSchijvenProductInfo {...options.details} />
  ),
  moederborden: (options) => <MoederbordProductInfo {...options.details} />,
  "pc-behuizingen": (options) => (
    <PcBehuizingProductInfo {...options.details} />
  ),
  ssds: (options) => <SsdProductInfo {...options.details} />,
  voedingen: (options) => <VoedingProductInfo {...options.details} />,
  waterkoeling: (options) => <WaterkoelingProductInfo {...options.details} />,
};

const keysToSearchOver = ["name", "brand", "productSubBlock"];

export default function ProductList({ search }) {
  const { getAll } = useFavorites();
  const [producten, setProducten] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [category, setCategory] = useState("alles");
  const [error, setError] = useState(null);

  console.log(filteredProducts);

  const handleRemoveFavorite = (productID) => {
    setProducten(
      producten.filter((product) => product.productID !== productID)
    );
  };

  useEffect(() => {
    const controller = new AbortController();
    const fetchProducten = async () => {
      try {
        const data = await getAll(controller);
        setError(null);
        setProducten(data);
        setFilteredProducts(data);
      } catch (error) {
        setError(error);
      }
    };
    fetchProducten();
    return () => {
      controller.abort();
    };
  }, [getAll]);

  useEffect(() => {
    setFilteredProducts(
      producten.filter((product) =>
        keysToSearchOver.some((key) => {
          let realCategoryName;
          if (category === "alles") {
            realCategoryName = "";
          } else {
            realCategoryName = category;
          }
          return (
            product[key]?.toLowerCase().includes(search.toLowerCase()) &&
            product.category.toLowerCase().includes(realCategoryName)
          );
        })
      )
    );
  }, [search, producten, category]);

  const currentProducten = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return filteredProducts.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, filteredProducts]);

  return (
    <>
      <Toaster
        containerStyle={{
          top: 20,
          left: 20,
          bottom: 20,
          right: 20,
        }}
      />
      <div className="flex justify-center mt-7">
        <Pagination
          currentPage={currentPage}
          totalCount={filteredProducts.length}
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
      <main className="md:flex my-7 px-7">
        <div id="filter" className="md:w-1/5 rounded-lg md:mr-7">
          <Menu>
            <Menu.Button className="btn w-full">
              categorie: {category}
            </Menu.Button>
            <Menu.Items className={"p-2 shadow bg-base-300 rounded-box"}>
              {Object.entries(categories).map(([key, value], index) => (
                <Menu.Item key={index}>
                  {({ active }) => (
                    <div
                      className={`btn border-none no-animation w-full ${
                        active && "btn-active"
                      }`}
                      onClick={() => {
                        setCategory(key);
                        setCurrentPage(1);
                      }}
                    >
                      <p className="mr-auto">
                        {key === "alles" ? "alles" : value}
                      </p>
                      <input
                        type="checkbox"
                        checked={category === key}
                        className="checkbox"
                        readOnly
                      />
                    </div>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Menu>
        </div>
        <div className="md:flex md:flex-wrap md:gap-8 md:w-4/5 md:ml-auto">
          <Error error={error} />
          {!error
            ? currentProducten.map((options) => (
                // vragen aan lector of dit correct is en of dit niet bad practice is wat is de beste manier om dit op te lossen.
                <ProductCard
                  key={options.productID}
                  styleName="md:max-w-sm md:min-w-min md:w-64 md:grow md:shrink"
                  productInfo={typeOfProducts[options.category](options)}
                  isFavorited={1}
                  handleRemoveFavorite={handleRemoveFavorite}
                  {...options}
                />
              ))
            : null}
        </div>
      </main>

      <div className="flex justify-center mt-7">
        <Pagination
          currentPage={currentPage}
          totalCount={filteredProducts.length}
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </>
  );
}
