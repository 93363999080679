export default function HardeSchijvenProductInfo(details) {
  return (
    <>
      {/* <p>- Capaciteit: {details.Capaciteit}</p>
      <p>
        - ms/cache/rpm:{" "}
        {details.Toegangstijd?.Lezen ? details.Toegangstijd?.Lezen : "-"}/
        {details.Cache}/{details.Toerental}
      </p>
      {details.Overdrachtsnelheid?.Lezen ? (
        <p>- Datatransferrate: {details.Overdrachtsnelheid?.Lezen}</p>
      ) : (
        <p>- Bouwvorm: {details.Bouwvorm}</p>
      )} */}
    </>
  );
}
