export default function WaterkoelingProductInfo(details) {
  return (
    <>
      {details?.Soort === "Waterkoeling kit" ? (
        <>
          <p>- socket: {details?.Sockets}</p>
          <p>- Soort: {details?.Soort}</p>
          <p>- Radiatorgrootte: {details?.["Radiator grootte"]}</p>
          <p>
            - Led-verlichting:{" "}
            {details?.["LED-verlichting"]?.["LED-verlichting"]}
          </p>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
