import { useAuth0 } from "@auth0/auth0-react";
import { Navigate } from "react-router";

export default function RequireAuth({ children }) {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <button className="btn loading"></button>;
  }

  if (isAuthenticated) {
    return children;
  }

  return <Navigate to="/login" replace={true} />;
}
