export default function CpuProductInfo(details) {
  return (
    <>
      <p>
        - kloksnelheid:{" "}
        {details.Kern?.Kloksnelheid
          ? details.Kern?.Kloksnelheid
          : "Geen informatie"}
      </p>
      <p>
        - cores:{" "}
        {details.Kern?.Aantal ? details.Kern?.Aantal : "Geen informatie"}
      </p>
      <p>- socket: {details.Socket}</p>
      {/* Cache moet in mooier formaat gezet worden formatting moet worden toegepast */}
      <p>- l3 cache: {details.Cache}</p>
    </>
  );
}
