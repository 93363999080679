import axios from 'axios';
import {
    useAuth0
} from '@auth0/auth0-react';
import {
    useCallback
} from 'react';

const baseUrl = 'https://what-parts-should-i-buy-api.onrender.com/api/favorieten';


const useFavorites = () => {
    const {
        getAccessTokenSilently,
    } = useAuth0();

    const getAll = useCallback(async () => {
        const token = await getAccessTokenSilently();

        let config = {
            method: 'get',
            url: baseUrl,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };

        const response = await axios(config);
        return response.data.items;
    }, [getAccessTokenSilently])

    const addFavorite = useCallback(async (productID) => {
        const token = await getAccessTokenSilently();

        let config = {
            method: 'post',
            url: baseUrl,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            data: {
                "productID": productID,
            }
        };

        const response = await axios(config);
        return response.data.items;
    }, [getAccessTokenSilently])

    const deleteFavorite = useCallback(async (productID) => {
        const token = await getAccessTokenSilently();

        let config = {
            method: 'delete',
            url: baseUrl,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            data: {
                "productID": productID,
            }
        };

        const response = await axios(config);
        return response.data.items;
    }, [getAccessTokenSilently])

    return {
        getAll,
        addFavorite,
        deleteFavorite
    };
}

export default useFavorites;