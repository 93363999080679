import axios from 'axios';
import {
    useAuth0
} from '@auth0/auth0-react';
import {
    useCallback
} from 'react';

const baseUrl = 'https://what-parts-should-i-buy-api.onrender.com/api/gebruiker';

const useUser = () => {
    const {
        getAccessTokenSilently,
    } = useAuth0();

    const getByID = useCallback(async () => {
        const token = await getAccessTokenSilently();

        let config = {
            method: 'get',
            url: baseUrl,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };

        const response = await axios(config);
        return response.data;
    }, [getAccessTokenSilently])

    return {
        getByID
    };
}

export default useUser;