export default function MoederbordProductInfo(details) {
  return (
    <>
      <p>- Formfactor: {details.Formfactor}</p>
      <p>
        - Grafisch:{" "}
        {details.Inbouwsloten?.["PCIe x16"]
          ? details.Inbouwsloten?.["PCIe x16"]
          : "Geen informatie"}
      </p>
      <p>- Socket: {details.Socket}</p>
      {details.Processor?.["Ondersteunde modellen"] ? (
        <p>
          - Processoren:{" "}
          {details.Processor?.["Ondersteunde modellen"]
            .split(",")
            .slice(0, 4)
            .join(",")}
        </p>
      ) : (
        <p>
          - Geheugen max: {details.Geheugen["Maximale hoeveelheid geheugen"]}
        </p>
      )}
    </>
  );
}
