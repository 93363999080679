import { useState, useEffect } from "react";
import useUser from "../../api/user";

export default function CurrentUserCanManageReview({ children, userID }) {
  const { getByID } = useUser();
  let [currentUser, setCurrentUser] = useState({});
  useEffect(() => {
    const getCurrentUser = async () => {
      setCurrentUser(await getByID());
    };
    getCurrentUser();
  }, [getByID]);
  if (currentUser.gebruikerID === userID) {
    return children;
  }
}
