export default function GeheugenProductInfo(details) {
  return (
    <>
      <p>- Capaciteit: {details.Capaciteit}</p>
      <p>
        - Timings: CL
        {details.Timings?.["CAS Latency (CL)"]
          ? details.Timings?.["CAS Latency (CL)"]
          : "-"}{" "}
        {details.Timings?.["RAS-to-CAS-Delay (tRCD)"]
          ? details.Timings?.["RAS-to-CAS-Delay (tRCD)"]
          : "-"}
        -
        {details.Timings?.["RAS-Precharge-Time (tRP)"]
          ? details.Timings?.["RAS-Precharge-Time (tRP)"]
          : "-"}
        -
        {details.Timings?.["Row-Active-Time (tRAS)"]
          ? details.Timings?.["Row-Active-Time (tRAS)"]
          : "-"}
      </p>
      <p>- Standaard: {details.Standaard}</p>
      <p>- Modules: {details.Module}</p>
    </>
  );
}
