import ProductCard from "./ProductCard";
import useProducts from "../api/producten";
import { useEffect, useState } from "react";
import Pagination from "./Pagination";
import {
  CpuProductInfo,
  CaseFanProductInfo,
  CpuKoelersProductInfo,
  GeheugenProductInfo,
  GrafischeKaartenProductInfo,
  HardeSchijvenProductInfo,
  MoederbordProductInfo,
  PcBehuizingProductInfo,
  SsdProductInfo,
  VoedingProductInfo,
  WaterkoelingProductInfo,
} from "./productCardInfo/index";
import toast, { useToasterStore } from "react-hot-toast";
import { Menu } from "@headlessui/react";
import Error from "./Error";
import { useMemo } from "react";

let PageSize = 24;
const typeOfProducts = {
  "case-fans": (options) => <CaseFanProductInfo {...options.details} />,
  "cpu-koelers": (options) => <CpuKoelersProductInfo {...options.details} />,
  cpus: (options) => <CpuProductInfo {...options.details} />,
  geheugen: (options) => <GeheugenProductInfo {...options.details} />,
  "grafische-kaarten": (options) => (
    <GrafischeKaartenProductInfo {...options.details} />
  ),
  "harde-schijven": (options) => (
    <HardeSchijvenProductInfo {...options.details} />
  ),
  moederborden: (options) => <MoederbordProductInfo {...options.details} />,
  "pc-behuizingen": (options) => (
    <PcBehuizingProductInfo {...options.details} />
  ),
  ssds: (options) => <SsdProductInfo {...options.details} />,
  voedingen: (options) => <VoedingProductInfo {...options.details} />,
  waterkoeling: (options) => <WaterkoelingProductInfo {...options.details} />,
};
const categories = {
  alles: "",
  "cpu-koelers": "cpu koelers",
  cpus: "cpu's",
  geheugen: "geheugen",
  "grafische-kaarten": "grafische kaarten",
  "harde-schijven": "harde schijven",
  moederborden: "moederborden",
  ssds: "ssd's",
  voedingen: "voedingen",
  waterkoeling: "waterkoeling",
  "pc-behuizingen": "pc behuizing",
  "case-fans": "case fans",
};
const TOAST_LIMIT = 1;

export default function ProductList({
  search,
  homeButtonPressed,
  setHomeButtonPressed,
  setSearch,
}) {
  const { getAll } = useProducts();
  const [producten, setProducten] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [category, setCategory] = useState("alles");
  const [error, setError] = useState(null);

  useEffect(() => {
    if (homeButtonPressed) {
      setCategory("alles");
      setHomeButtonPressed(false);
      setSearch("");
      setCurrentPage(1);
    }
  }, [homeButtonPressed, setHomeButtonPressed, setSearch]);

  const { toasts } = useToasterStore();

  useEffect(() => {
    toasts
      .filter((t) => t.visible)
      .filter((_, i) => i >= TOAST_LIMIT)
      .forEach((t) => toast.dismiss(t.id));
  }, [toasts]);

  useEffect(() => {
    const controller = new AbortController();
    const fetchProducten = async () => {
      try {
        setProducten([]);
        setIsLoading(true);
        const data = await getAll({
          controller,
          limit: PageSize,
          offset: (currentPage - 1) * PageSize,
          category,
          search,
        });
        setIsLoading(false);
        setError(null);
        setProducten(data.items);
        setTotalCount(data.count);
      } catch (error) {
        if (error.message !== "canceled") {
          setIsLoading(false);
          setError(error);
        }
      }
    };
    fetchProducten();
    return () => {
      controller.abort();
    };
  }, [getAll, currentPage, category, search]);

  const List = useMemo(() => {
    return producten.map((options) => (
      // vragen aan lector of dit correct is en of dit niet bad practice is wat is de beste manier om dit op te lossen.
      <ProductCard
        key={options.productID}
        styleName="md:max-w-md md:min-w-min md:w-64 md:grow md:shrink"
        productInfo={typeOfProducts[options.category](options)}
        {...options}
      />
    ));
  }, [producten]);

  return (
    <>
      <div className="flex justify-center mt-7">
        <Pagination
          currentPage={currentPage}
          totalCount={totalCount}
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
      <main className="md:flex my-7 px-7">
        <div id="filter" className="md:w-1/5 rounded-lg md:mr-7">
          <Menu>
            <Menu.Button className="btn w-full">
              categorie: {category}
            </Menu.Button>
            <Menu.Items className={"p-2 shadow bg-base-300 rounded-box"}>
              {Object.entries(categories).map(([key, value], index) => (
                <Menu.Item key={index}>
                  {({ active }) => (
                    <div
                      className={`btn border-none no-animation w-full ${
                        active && "btn-active"
                      }`}
                      onClick={() => {
                        setCategory(key);
                        setCurrentPage(1);
                      }}
                    >
                      <p className="mr-auto">
                        {key === "alles" ? "alles" : value}
                      </p>
                      <input
                        type="checkbox"
                        checked={category === key}
                        className="checkbox"
                        readOnly
                      />
                    </div>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Menu>
        </div>
        <div className="md:flex md:flex-wrap md:gap-8 md:w-4/5 md:ml-auto">
          {isLoading && (
            <button className="btn loading" data-cy="loading">
              laden...
            </button>
          )}
          <Error error={error} />
          {!error ? List : null}
        </div>
      </main>

      {!isLoading && (
        <div className="flex justify-center mt-7">
          <Pagination
            currentPage={currentPage}
            totalCount={totalCount}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      )}
    </>
  );
}
