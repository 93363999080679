import { Link } from "react-router-dom";
import { FaHeart } from "react-icons/fa";
import { useState } from "react";
import useFavorites from "../api/favorieten";
import toast from "react-hot-toast";
import { useAuth0 } from "@auth0/auth0-react";

export default function ProductCard({
  brand,
  name,
  styleName,
  price,
  imageUrl,
  productSubBlock,
  productInfo,
  productID,
  category,
  isFavorited,
  handleRemoveFavorite,
}) {
  const { isAuthenticated } = useAuth0();
  let favorite;
  if (isFavorited === 1) {
    favorite = true;
  } else {
    favorite = false;
  }
  const [isFavorite, setIsFavorite] = useState(favorite);
  const { addFavorite, deleteFavorite } = useFavorites();
  const handleFavoriteClick = async () => {
    if (!isAuthenticated) {
      toast.error(
        "Je moet inloggen om producten aan favorieten toe te voegen."
      );
    } else {
      if (isFavorite) {
        await deleteFavorite(productID);
      } else {
        await addFavorite(productID);
      }
      setIsFavorite(!isFavorite);
      handleRemoveFavorite && handleRemoveFavorite(productID);
    }
  };
  return (
    <div
      className={`card mt-7 md:mt-0 bg-base-300 shadow-xl ${styleName}`}
      data-cy="productcard"
    >
      <figure className="px-5 pt-5">
        {imageUrl === "https://www.alternate.beNone" ? (
          "Geen image"
        ) : (
          <img src={imageUrl} alt="Product" className="rounded-xl" />
        )}
      </figure>
      <div className="card-body">
        <h2 className="card-title text-center">{`${brand} ${name}`}</h2>
        {productSubBlock && (
          <h3 className="mb-4 text-center">{productSubBlock}</h3>
        )}
        {productInfo}
        <h2 className="text-2xl font-medium mt-4 self-center">{price}</h2>
        <div className="card-actions self-center items-center">
          <Link to={`/product/${category?.split(" ").join("-")}/${productID}`}>
            <button className="btn btn-primary" data-cy="meerInfoButton">
              Meer info
            </button>
          </Link>
          <FaHeart
            className="cursor-pointer select-none"
            color={`${isFavorite ? "red" : ""}`}
            onClick={handleFavoriteClick}
            size={"1.7em"}
            data-cy="favoriteButton"
          />
        </div>
      </div>
    </div>
  );
}
