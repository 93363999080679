import HomePage from "./components/HomePage";
import NavbarWithoutSearch from "./components/navbar/NavbarWithoutSearch";
import ProductInfo from "./components/ProductInfo";
import { useParams } from "react-router";
import FavorietenPage from "./components/FavorietenPage";

export const Home = () => (
  <div>
    <HomePage />
  </div>
);

export const ProductPage = () => {
  const { productID } = useParams();
  return (
    <>
      <NavbarWithoutSearch />
      <main>
        <ProductInfo productID={productID} />
      </main>
    </>
  );
};

export const Favorieten = () => <FavorietenPage />;

export const NotFound = () => {
  return (
    <div>
      <h1>Pagina niet gevonden</h1>
      <p>Er is geen pagina op deze url, probeer iets anders.</p>
    </div>
  );
};
