import { useEffect } from "react";
import { FaMoon, FaSun } from "react-icons/fa";
import { themeChange } from "theme-change";

export default function ToggleThemeButton() {
  useEffect(() => {
    themeChange(false);
  }, []);

  return (
    <>
      <button
        className="btn btn-square"
        data-set-theme="light"
        data-act-class="hidden"
      >
        <FaSun size="1.7em" />
      </button>
      <button
        className="btn btn-square"
        data-set-theme="dark"
        data-act-class="hidden"
      >
        <FaMoon size="1.7em" />
      </button>
    </>
  );
}
