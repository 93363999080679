import Navbar from "./navbar/NavbarWithSearch";
import ProductList from "./ProductList";
import { useState } from "react";

export default function HomePage() {
  const [query, setQuery] = useState("");
  const [search, setSearch] = useState("");
  const [homeButtonPressed, setHomeButtonPressed] = useState(false);
  return (
    <>
      <Navbar
        query={query}
        setQuery={setQuery}
        setSearch={setSearch}
        setHomeButtonPressed={setHomeButtonPressed}
      />
      <ProductList
        search={search}
        homeButtonPressed={homeButtonPressed}
        setSearch={setSearch}
        setHomeButtonPressed={setHomeButtonPressed}
      />
    </>
  );
}
