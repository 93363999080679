import { FaHome, FaHeart, FaSun, FaMoon } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import AuthenticationButton from "../authentication/AuthenticationButton";
import ToggleThemeButton from "../ToggleThemeButton";

export default function Navbar({ setHomeButtonPressed }) {
  const location = useLocation();

  return (
    <nav>
      <div className="navbar bg-base-100 px-24 justify-center gap-10 xl:gap-0">
        <div className="xl:flex-1 justify-center gap-10">
          <Link
            to="/"
            className={`btn ${
              location.pathname === "/" ? "btn-primary" : ""
            } btn-square`}
            onClick={() => setHomeButtonPressed(true)}
            data-cy="homePageButton"
          >
            <FaHome size="1.7em" />
          </Link>
          <AuthenticationButton />
          <Link
            to="/favorieten"
            className={`btn ${
              location.pathname === "/favorieten" ? "btn-primary" : ""
            }  btn-square`}
            data-cy="favorietenPageButton"
          >
            <FaHeart size="1.7em" />
          </Link>
        </div>
        <ToggleThemeButton />
      </div>
    </nav>
  );
}
