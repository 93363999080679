export default function CpuKoelersProductInfo(details) {
  return (
    <>
      {details.soort?.toLowerCase().includes("waterkoeling") ? (
        <>
          <p>- Socket: {details.Sockets}</p>
          <p>- Soort: {details.soort}</p>
          <p>- Radiatorgrootte: {details["Radiator grootte"]}</p>
          <p>
            - Led-verlichting: {details["LED-verlichting"]["LED-verlichting"]}
          </p>
        </>
      ) : (
        <>
          <p>- Soort: {details.Soort}</p>
          <p>- toerental: {details.Toerental}</p>
          <p>- geluidssterkte: {details.Geluidsniveau}</p>
          <p>- bevestiging: {details.Bevestiging}</p>
        </>
      )}
    </>
  );
}
