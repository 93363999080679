import useProducts from "../api/producten";
import React, { useEffect, useState } from "react";
import { FaHeart, FaExclamationTriangle } from "react-icons/fa";
import { v4 } from "uuid";
import Review from "./Review";
import useFavorites from "../api/favorieten";
import toast, { useToasterStore } from "react-hot-toast";
import { useAuth0 } from "@auth0/auth0-react";
import { useForm } from "react-hook-form";

const tabOptions = {
  beschrijving: "beschrijving",
  details: "details",
  reviews: "reviews",
};
const TOAST_LIMIT = 1;

export default function ProductInfo({ productID }) {
  const { toasts } = useToasterStore();
  const [product, setProduct] = useState({});
  const [tabOption, setTabOption] = useState(tabOptions.beschrijving);
  const [reviews, setReviews] = useState([]);
  const { getById, getAllReviewsForProduct, createReview, deleteReview } =
    useProducts();
  const [isFavorite, setIsFavorite] = useState(false);
  const { addFavorite, deleteFavorite } = useFavorites();
  const { isAuthenticated } = useAuth0();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const { review } = data;
    if (!isAuthenticated) {
      toast.error(
        "Je moet inloggen om reviews aan producten te kunnen toevoegen."
      );
    } else {
      const newReview = await createReview(productID, review);
      setReviews([newReview, ...reviews]);
    }
    reset();
  };

  const handleFavoriteClick = async () => {
    if (!isAuthenticated) {
      toast.error(
        "Je moet inloggen om producten aan favorieten te kunnen toevoegen."
      );
    } else {
      if (isFavorite) {
        await deleteFavorite(productID);
      } else {
        await addFavorite(productID);
      }
      setIsFavorite(!isFavorite);
    }
  };

  useEffect(() => {
    toasts
      .filter((t) => t.visible)
      .filter((_, i) => i >= TOAST_LIMIT)
      .forEach((t) => toast.dismiss(t.id));
  }, [toasts]);

  useEffect(() => {
    const fetchProduct = async () => {
      const responseData = await getById(productID);
      setProduct(responseData);
      setIsFavorite(responseData.isFavorited);
    };
    const fetchReviews = async () => {
      const responseData = await getAllReviewsForProduct(productID);
      setReviews(responseData);
    };
    fetchReviews();
    fetchProduct();
  }, [productID, getAllReviewsForProduct, getById]);

  const handleRemoveReview = async (reviewID) => {
    await deleteReview(productID, reviewID);
    setReviews(reviews.filter((review) => review.reviewID !== reviewID));
  };

  return (
    <div className="sm:w-4/5 bg-base-200 rounded-lg sm:mx-auto mt-7 p-4 mx-4">
      <div className="lg:grid lg:grid-cols-[minmax(min-content,_1fr)_minmax(min-content,_1fr)]">
        <h1 className="col-span-2 font-semibold text-3xl text-center lg:text-start">
          {product.brand}
          {product.name}
        </h1>
        <h3 className="col-span-2 font-medium mb-7 text-center lg:text-start">
          {product.productSubBlock}
        </h3>
        <figure className="lg:justify-self-center mb-7 lg:mb-0">
          <img
            src={product.imageUrl}
            alt="product"
            className="max-w-md w-full sm:min-w-[18rem] rounded-lg mx-auto"
          />
        </figure>
        <div className="self-center">
          <p className="text-3xl font-semibold mb-7 text-center">
            {product.price}
          </p>
          <div className="flex items-center justify-center ml-7">
            <button className="btn btn-primary shrink">
              Ga naar product op alternate
            </button>

            <FaHeart
              className="inline-block cursor-pointer select-none ml-3"
              color={`${isFavorite ? "red" : ""}`}
              onClick={handleFavoriteClick}
              size={"1.7em"}
            />
          </div>
        </div>
        <div className="tabs tabs-boxed col-span-2 font-semibold mt-7 justify-center lg:justify-start">
          <p
            className={`tab ${
              tabOption === tabOptions.beschrijving ? "tab-active" : ""
            }`}
            onClick={() => {
              setTabOption(tabOptions.beschrijving);
            }}
          >
            Beschrijving
          </p>
          <p
            className={`tab ${
              tabOption === tabOptions.details ? "tab-active" : ""
            }`}
            onClick={() => {
              setTabOption(tabOptions.details);
            }}
          >
            Details
          </p>
          <p
            className={`tab ${
              tabOption === tabOptions.reviews ? "tab-active" : ""
            }`}
            onClick={() => {
              setTabOption(tabOptions.reviews);
            }}
            data-cy="reviewTabButton"
          >
            Reviews
          </p>
        </div>
        <p
          className={`col-span-2 bg-base-100 rounded-lg p-3 ${
            tabOption === tabOptions.beschrijving ? "block" : "hidden"
          }`}
        >
          {product.description}
        </p>
        <div
          className={`col-span-2 bg-base-100 rounded-lg p-3 ${
            tabOption === tabOptions.details ? "block" : "hidden"
          }`}
        >
          {/* find a correct value or width to fix that the first column goes to min-content if needed */}
          <div className="grid grid-cols-[minmax(auto,_max-content)_1fr_1fr]">
            {product.details &&
              Object.entries(product.details).map(([key, value]) => {
                if (typeof value === "object") {
                  return (
                    <React.Fragment key={v4()}>
                      <p className="text-end font-medium">{key}:</p>
                      {value[key] && (
                        <p className="col-span-2 ml-5">{value[key]}</p>
                      )}
                      {Object.entries(value).map(([keyValue, valueValue]) => {
                        if (typeof valueValue === "object") {
                          return (
                            keyValue !== key && (
                              <React.Fragment key={v4()}>
                                {Object.entries(valueValue).map(
                                  ([keyValueValue, valueValueValue]) => {
                                    return (
                                      <p
                                        className="col-start-2 col-span-2 ml-5"
                                        key={v4()}
                                      >
                                        {keyValueValue}: {valueValueValue}
                                      </p>
                                    );
                                  }
                                )}
                              </React.Fragment>
                            )
                          );
                        } else {
                          return (
                            keyValue !== key && (
                              <React.Fragment key={v4()}>
                                <p className="col-start-2 col-span-2 ml-5 self-end">
                                  {keyValue}: {valueValue}
                                </p>
                              </React.Fragment>
                            )
                          );
                        }
                      })}
                    </React.Fragment>
                  );
                } else {
                  return (
                    <React.Fragment key={v4()}>
                      <p className="text-end font-medium">{key}:</p>
                      {key.toLowerCase() === "kenmerken" ? (
                        <p className="col-span-2 ml-5">
                          {value.split(/\.? - /).map((kenmerk) => (
                            <React.Fragment key={v4()}>
                              -{" "}
                              {kenmerk.charAt(0) === "-"
                                ? kenmerk.slice(2)
                                : kenmerk}
                              <br />
                            </React.Fragment>
                          ))}
                        </p>
                      ) : (
                        <p className="col-span-2 ml-5 self-end">{value}</p>
                      )}
                    </React.Fragment>
                  );
                }
              })}
          </div>
        </div>
      </div>
      <div
        className={`max-w-full bg-base-100 rounded-lg p-3 ${
          tabOption === tabOptions.reviews ? "block" : "hidden"
        }`}
      >
        <form onSubmit={handleSubmit(onSubmit)} className="flex items-center">
          <textarea
            {...register("review", {
              required: "Review veld invullen is verplicht.",
              disabled: !isAuthenticated,
              minLength: {
                value: 10,
                message: "De minimum lengte is 10 karakters.",
              },
            })}
            className="textarea textarea-primary bg-base-300 grow"
            data-cy="textAreaReview"
          />
          <button
            type="submit"
            className="btn btn-primary ml-3"
            data-cy="createReviewButton"
          >
            Plaats review
          </button>
        </form>
        {errors.review ? (
          <div className="alert alert-warning shadow-lg w-max mt-3">
            <FaExclamationTriangle size="1.7em" />
            <p>{errors.review.message}</p>
          </div>
        ) : null}

        <div>
          {reviews.map((review) => (
            <Review
              key={review.reviewID}
              name={review.naam}
              date={review.datum}
              time={review.tijd}
              message={review.bericht}
              productID={productID}
              reviewID={review.reviewID}
              userID={review.reviewsGebruikerID}
              removeHandler={() => handleRemoveReview(review.reviewID)}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
